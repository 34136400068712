<div class="displayTable">
  <div class="displayTableCell">

    <!--
    <div class="px-logo">
      <a href="https://www.sportapp.io" target="_blank">
        <img src="" alt="">
      </a>
    </div>
    -->

    <div class="authBlock">
      <div class="formGroup" style="text-align: center;">
        <img src="/assets/img/sportest_horizontal_blog_small.png" width="132" height="44" class="d-inline-block align-top" alt="">
      </div>


      <h3>Registrar / Iniciar Sesión</h3>

      <!--
      <div class="formGroup">
        <input type="email" class="formControl" placeholder="Email" #userName required>
      </div>

      <div class="formGroup">
        <input type="password" class="formControl" placeholder="Password" #userPassword required>
      </div>

      <div class="formGroup">
        <input type="button" class="btn btnPrimary" value="Iniciar Sesión"
          (click)="authService.SignIn(userName.value, userPassword.value)">
      </div>

      <div class="redirectToLogin">
        <span>¿Aún no tienes cuenta SporTest?</span>
        <br><br>
        <span class="redirect" routerLink="/register"><b>REGÍSTRATE AQUÍ</b></span>
      </div>

      <div class="formGroup">
        <span class="or"><span class="orInner">O</span></span>
      </div>
      -->

      <div class="formGroup">
        <button type="button" class="btn googleBtn" (click)="authService.GoogleAuth()">
          <i class="fab fa-google-plus-g"></i>
          Iniciar Sesión con Google
        </button>
      </div>

      <!--
      <div class="formGroup">
        <button type="button" class="btn facebookBtn" (click)="authService.FacebookAuth()">
          <i class="fab fa-facebook"></i>
          Iniciar Sesión con Facebook
        </button>
      </div>

      <div class="formGroup">
        <button type="button" class="btn twitterBtn" (click)="authService.TwitterAuth()">
          <i class="fab fa-twitter"></i>
          Iniciar Sesión con Twitter
        </button>
      </div>
      -->

      <!--
      <div class="forgotPassword">
        <span routerLink="/forgot-password">¿Se te olvidó tu contraseña?</span>
      </div>
      -->
    </div>

  </div>
</div>