import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bench-press-fm',
  templateUrl: './bench-press-fm.component.html',
  styleUrls: ['./bench-press-fm.component.css']
})
export class BenchPressFmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
