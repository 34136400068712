import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ApiTestService } from '../../../../../shared/services/api-test.service';
import { GoogleAnalyticsService } from '../../../../../google-analytics.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-mmae-sarcopenia',
  templateUrl: './mmae-sarcopenia.component.html',
  styleUrls: ['./mmae-sarcopenia.component.css']
})
export class MmaeSarcopeniaComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title
  ) 
  { this.titleService.setTitle("SporTest App - Test - MMAE Sarcopenia"); }

  categoria = 'Salud';
  test: any = [];
  semaforo = 'resultado';
  public loading: boolean = false;

  ////u = JSON.parse(localStorage.getItem('user'));
  ////uuid = (this.u != null) ? String(this.u.uid) : '';

  dataForm = this.fb.group({
    uuid: [(localStorage.getItem('uid') !== null)?localStorage.getItem('uid'):''],
    categoria: [this.categoria],
    genero: ['', Validators.required],
    edad: ['', Validators.required],
    peso: ['', Validators.required],
    altura_rodilla: ['', Validators.required],
    circunferencia_pantorrilla: ['', Validators.required],
    dinamometria: ['', Validators.required],
    circunferencia_cadera: ['', Validators.required],
    mmae: [{ value: '', disabled: true }],
    resultado: [{ value: '', disabled: true }]
  });

  ngOnInit() {
    this.httpClient.get('assets/json/salud/mmae-sarcopenia.json').subscribe(data => {
      this.test = data['mmae_sarcopenia'];
    });
  }

  calcularTest() {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.loading = true;

    this.apiTestService.MmaeSarcopeniaTest(this.dataForm.value).subscribe(res => {

      this.loading = false;

      this.dataForm.patchValue({
        mmae: res.valores.mmae,
        resultado: res.valores.clasificacion
      });

      let valor = Number(res.valores.valor) || 0;
      
      if (valor == 1) {
        this.semaforo = 'resultado_verde';
      } else if (valor == 2) {
        this.semaforo = 'resultado_rojo';
      } else {
        this.semaforo = 'resultado';
      }
      
    });
  }

}
