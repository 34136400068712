import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';

// Reactive Form
import { ReactiveFormsModule } from '@angular/forms';

// App routing modules
import { AppRoutingModule } from './shared/routing/app-routing.module';

// App components
import { AppComponent } from './app.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/auth/verify-email/verify-email.component';

// Firebase services + enviorment module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

// Services
import { AuthService } from './shared/services/auth.service';
import { ApiTestService } from './shared/services/api-test.service';
import { DbService } from './shared/services/db.service';
import { GoogleAnalyticsService } from './google-analytics.service';

import { TopBarNavComponent } from './components/menu/top-bar-nav/top-bar-nav.component';
import { SidebarNavComponent } from './components/menu/sidebar-nav/sidebar-nav.component';
import { ProfileComponent } from './components/auth/profile/profile.component';

import { ParqAndYouComponent } from './components/tests/public/salud/parq-and-you/parq-and-you.component';
import { FcmaxComponent } from './components/tests/public/salud/fcmax/fcmax.component';
import { ImcComponent } from './components/tests/public/salud/imc/imc.component';
import { WaistPerimeterComponent } from './components/tests/public/salud/waist-perimeter/waist-perimeter.component';
import { TwoMinutesStepComponent } from './components/tests/public/aptitud-fisica/two-minutes-step/two-minutes-step.component';
import { TugComponent } from './components/tests/public/aptitud-fisica/tug/tug.component';
import { GaitSpeedComponent } from './components/tests/public/aptitud-fisica/gait-speed/gait-speed.component';
import { ArmCurlComponent } from './components/tests/public/aptitud-fisica/arm-curl/arm-curl.component';
import { HandGripComponent } from './components/tests/public/aptitud-fisica/hand-grip/hand-grip.component';
import { ChairSitAndReachComponent } from './components/tests/public/aptitud-fisica/chair-sit-and-reach/chair-sit-and-reach.component';
import { LegPressOneRmComponent } from './components/tests/public/aptitud-fisica/leg-press-one-rm/leg-press-one-rm.component';
import { CooperComponent } from './components/tests/public/aptitud-fisica/cooper/cooper.component';
import { UmttComponent } from './components/tests/public/aptitud-fisica/umtt/umtt.component';
import { BenchPressRmComponent } from './components/tests/public/aptitud-fisica/bench-press-rm/bench-press-rm.component';
import { RastComponent } from './components/tests/public/aptitud-fisica/rast/rast.component';
import { BruceComponent } from './components/tests/public/aptitud-fisica/bruce/bruce.component';
import { PushUpComponent } from './components/tests/public/aptitud-fisica/push-up/push-up.component';
import { LongJumpFeetComponent } from './components/tests/public/aptitud-fisica/long-jump-feet/long-jump-feet.component';
import { SitUpComponent } from './components/tests/public/aptitud-fisica/sit-up/sit-up.component';
import { ChairStandComponent } from './components/tests/public/aptitud-fisica/chair-stand/chair-stand.component';
import { MmaeSarcopeniaComponent } from './components/tests/public/salud/mmae-sarcopenia/mmae-sarcopenia.component';
import { ShuttleRunComponent } from './components/tests/public/aptitud-fisica/shuttle-run/shuttle-run.component';
import { YoyoIrOneComponent } from './components/tests/public/aptitud-fisica/yoyo-ir-one/yoyo-ir-one.component';
import { YoyoIrTwoComponent } from './components/tests/public/aptitud-fisica/yoyo-ir-two/yoyo-ir-two.component';
import { FpWeltmanComponent } from './components/tests/public/antropometria/fp-weltman/fp-weltman.component';
import { FpDurninWomersleyComponent } from './components/tests/public/antropometria/fp-durnin-womersley/fp-durnin-womersley.component';
import { FpSlaughterComponent } from './components/tests/public/antropometria/fp-slaughter/fp-slaughter.component';
import { FpCarterComponent } from './components/tests/public/antropometria/fp-carter/fp-carter.component';
import { FpFaulknerComponent } from './components/tests/public/antropometria/fp-faulkner/fp-faulkner.component';
import { FpJPSevenPComponent } from './components/tests/public/antropometria/fp-j-p-seven-p/fp-j-p-seven-p.component';
import { FpJPThreePComponent } from './components/tests/public/antropometria/fp-j-p-three-p/fp-j-p-three-p.component';
import { CourseNavetteSrtComponent } from './components/tests/public/aptitud-fisica/course-navette-srt/course-navette-srt.component';
import { LegPowerComponent } from './components/tests/public/aptitud-fisica/leg-power/leg-power.component';
import { BenchPressFmComponent } from './components/tests/public/aptitud-fisica/bench-press-fm/bench-press-fm.component';
import { FpWithersComponent } from './components/tests/public/antropometria/fp-withers/fp-withers.component';
import { FmsComponent } from './components/tests/public/aptitud-fisica/fms/fms.component';
import { RuffierComponent } from './components/tests/public/aptitud-fisica/ruffier/ruffier.component';
import { CategoriasComponent } from './components/sportest/categorias/categorias.component';
import { CategoriaSaludComponent } from './components/sportest/categoria-salud/categoria-salud.component';
import { CategoriaAntropometriaComponent } from './components/sportest/categoria-antropometria/categoria-antropometria.component';
import { CategoriaAptitudFisicaComponent } from './components/sportest/categoria-aptitud-fisica/categoria-aptitud-fisica.component';
import { GastocaloricoComponent } from './components/tests/public/salud/gastocalorico/gastocalorico.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    TopBarNavComponent,
    SidebarNavComponent,
    ProfileComponent,
    ParqAndYouComponent,
    PushUpComponent,
    FcmaxComponent,
    ImcComponent,
    WaistPerimeterComponent,
    TwoMinutesStepComponent,
    TugComponent,
    GaitSpeedComponent,
    ArmCurlComponent,
    ChairStandComponent,
    HandGripComponent,
    ChairSitAndReachComponent,
    LegPressOneRmComponent,
    CooperComponent,
    UmttComponent,
    BenchPressRmComponent,
    RastComponent,
    BruceComponent,
    LongJumpFeetComponent,
    SitUpComponent,
    ChairStandComponent,
    MmaeSarcopeniaComponent,
    ShuttleRunComponent,
    YoyoIrOneComponent,
    YoyoIrTwoComponent,
    FpWeltmanComponent,
    FpDurninWomersleyComponent,
    FpSlaughterComponent,
    FpCarterComponent,
    FpFaulknerComponent,
    FpJPSevenPComponent,
    FpJPThreePComponent,
    CourseNavetteSrtComponent,
    LegPowerComponent,
    BenchPressFmComponent,
    FpWithersComponent,
    FmsComponent,
    RuffierComponent,
    CategoriasComponent,
    CategoriaSaludComponent,
    CategoriaAntropometriaComponent,
    CategoriaAptitudFisicaComponent,
    GastocaloricoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [AuthService, ApiTestService, GoogleAnalyticsService, DbService],
  bootstrap: [AppComponent]
})

export class AppModule { }
