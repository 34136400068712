import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ApiTestService } from '../../../../../shared/services/api-test.service';
import { GoogleAnalyticsService } from '../../../../../google-analytics.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-rast',
  templateUrl: './rast.component.html',
  styleUrls: ['./rast.component.css']
})
export class RastComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title
  ) 
  { this.titleService.setTitle("SporTest App - Test - Rast"); }

  categoria = 'Aptitud Física';
  test: any = [];
  semaforo = 'resultado';
  public loading: boolean = false;
  public mostrarTablaResultados: boolean = false;


  dataForm = this.fb.group({
    uuid: [(localStorage.getItem('uid') !== null)?localStorage.getItem('uid'):''],
    categoria: [this.categoria],
    genero: ['', Validators.required],
    edad: ['', Validators.required],
    peso: ['', Validators.required],

    tiempo_1: ['', Validators.required],
    tiempo_2: ['', Validators.required],
    tiempo_3: ['', Validators.required],
    tiempo_4: ['', Validators.required],
    tiempo_5: ['', Validators.required],
    tiempo_6: ['', Validators.required],

    indice_fatiga: [{ value: '', disabled: true }],
    potencia1: [{ value: '', disabled: true }],
    potencia2: [{ value: '', disabled: true }],
    potencia3: [{ value: '', disabled: true }],
    potencia4: [{ value: '', disabled: true }],
    potencia5: [{ value: '', disabled: true }],
    potencia6: [{ value: '', disabled: true }],
    potencia_maxima: [{ value: '', disabled: true }],
    potencia_media: [{ value: '', disabled: true }],
    potencia_minima: [{ value: '', disabled: true }]
  });

  ngOnInit() {
    this.httpClient.get('assets/json/aptitud-fisica/rast.json').subscribe(data => {
      this.test = data['rast'];
    });
  }

  calcularTest() {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.loading = true;

    this.apiTestService.RastTest(this.dataForm.value).subscribe(res => {

      this.loading = false;
      this.mostrarTablaResultados = true;

      this.dataForm.patchValue({

        indice_fatiga: res.valores.resultados.indice_de_fatiga,
        potencia1: res.valores.resultados.potencia1,
        potencia2: res.valores.resultados.potencia2,
        potencia3: res.valores.resultados.potencia3,
        potencia4: res.valores.resultados.potencia4,
        potencia5: res.valores.resultados.potencia5,
        potencia6: res.valores.resultados.potencia6,
        potencia_maxima: res.valores.resultados.potencia_maxima,
        potencia_media: res.valores.resultados.potencia_media,
        potencia_minima: res.valores.resultados.potencia_minima,
      });
    });
  }

}
