import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ApiTestService } from '../../../../../shared/services/api-test.service';
import { GoogleAnalyticsService } from '../../../../../google-analytics.service';
import { Title } from "@angular/platform-browser";
import { SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-fms',
  templateUrl: './fms.component.html',
  styleUrls: ['./fms.component.css']
})
export class FmsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title,
    private sanitizer: DomSanitizer
  ) 
  { this.titleService.setTitle("SporTest App - Test - FMS"); }

  categoria = 'Aptitud Física';
  test: any = [];
  semaforo = 'resultado';
  public loading: boolean = false;
  public radarBase64: SafeResourceUrl;
  public mostrarRadar: boolean = false;


  dataForm = this.fb.group({
    uuid: [(localStorage.getItem('uid') !== null)?localStorage.getItem('uid'):''],
    categoria: [this.categoria],
    genero: ['', Validators.required],
    edad: ['', Validators.required],
    deep_squat: ['', Validators.required],
    hurdle_step: ['', Validators.required],
    in_line_lunge: ['', Validators.required],
    shoulder_movility: ['', Validators.required],
    active_straigth_leg_raise: ['', Validators.required],
    trunk_stability_push_up: ['', Validators.required],
    rotary_stability: ['', Validators.required], 

    resultado: [{ value: '', disabled: true }],
    resultado_active_straigth_leg_raise: [{ value: '', disabled: true }],
    resultado_deep_squat: [{ value: '', disabled: true }],
    resultado_hurdle_step: [{ value: '', disabled: true }],
    resultado_in_line_lunge: [{ value: '', disabled: true }],
    resultado_rotary_stability: [{ value: '', disabled: true }],
    resultado_shoulder_movility: [{ value: '', disabled: true }],
    resultado_trunk_stability_push_up: [{ value: '', disabled: true }],
    score: [{ value: '', disabled: true }]

  });

  ngOnInit() {
    this.httpClient.get('assets/json/aptitud-fisica/fms.json').subscribe(data => {
      this.test = data['fms'];
    });
  }

  calcularTest() {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.loading = true;

    this.apiTestService.FmsTest(this.dataForm.value).subscribe(res => {

      this.loading = false;

      this.radarBase64 = this.sanitizer.sanitize(SecurityContext.NONE, this.sanitizer.bypassSecurityTrustResourceUrl(res.valores.radar));
      this.mostrarRadar = true;

      this.dataForm.patchValue({
        resultado: res.valores.clasificacion,
        resultado_active_straigth_leg_raise: res.valores.resultados.resultado_active_straigth_leg_raise,
        resultado_deep_squat: res.valores.resultados.resultado_deep_squat,
        resultado_hurdle_step: res.valores.resultados.resultado_hurdle_step,
        resultado_in_line_lunge: res.valores.resultados.resultado_in_line_lunge,
        resultado_rotary_stability: res.valores.resultados.resultado_rotary_stability,
        resultado_shoulder_movility: res.valores.resultados.resultado_shoulder_movility,
        resultado_trunk_stability_push_up: res.valores.resultados.resultado_trunk_stability_push_up,
        score: res.valores.resultados.score
      });

      let score = Number(res.valores.resultados.score) || 0;
      
      if (score < 15) {
        this.semaforo = 'resultado_rojo';
      } else if (score >= 15) {
        this.semaforo = 'resultado_verde';
      }

    });
  }

}
