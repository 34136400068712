
export const environment = {
  production: false,

  //TEST: Proyecto creado el 2 agosto 2022 en Firebase
  /*
  firebase: {
    apiKey: "AIzaSyCMjCd6LMjrpnDh_pmttoRRQ4JrQXjFyL4",
    authDomain: "test-sportest-2022.firebaseapp.com",
    projectId: "test-sportest-2022",
    storageBucket: "test-sportest-2022.appspot.com",
    messagingSenderId: "219176549506",
    appId: "1:219176549506:web:80c35e1bfb8c27e038089b",
    measurementId: "G-9CHVPWL73R"
  }
  */

  //PROD
  firebase: {
    apiKey: "AIzaSyD0TEuV_NUu490cpZB36oni40WzONpizPg",
    authDomain: "sportest-a1588.firebaseapp.com",
    databaseURL: "https://sportest-a1588.firebaseio.com",
    projectId: "sportest-a1588",
    storageBucket: "sportest-a1588.appspot.com",
    messagingSenderId: "691363749177"
    //appId: "1:691363749177:web:b4b7b5a759a32e6bef1ce8",
    //measurementId: "G-4ETJ81GNZ8"
  }
};
