import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ApiTestService } from '../../../../../shared/services/api-test.service';
import { GoogleAnalyticsService } from '../../../../../google-analytics.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-fcmax',
  templateUrl: './fcmax.component.html',
  styleUrls: ['./fcmax.component.css']
})
export class FcmaxComponent  implements OnInit {

  constructor(
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title
  ) 
  { this.titleService.setTitle("SporTest App - Test - Fcmax (Karvonen, Tanaka, Astrand)"); }

  categoria = 'Salud';
  test: any = [];
  public loading: boolean = false;

  k60 = '';
  k70 = '';
  k80 = '';
  k90 = '';
  k100 = '';
  tanaka = '';
  astrand = '';

  /*
  Formulas: Array<any> = [
    { name: 'Karvonen', value: 'Karvonen' },
    { name: 'Tanaka', value: 'Tanaka' },
    { name: 'Astrand', value: 'Astrand' }
  ];
  */

  ////u = JSON.parse(localStorage.getItem('user'));
  ////uuid = (this.u != null) ? String(this.u.uid) : '';

  dataForm = this.fb.group({
    uuid: [(localStorage.getItem('uid') !== null)?localStorage.getItem('uid'):''],
    categoria: [this.categoria],
    genero: ['', Validators.required],
    edad: ['', Validators.required],
    formulas: this.fb.array(['Karvonen','Tanaka','Astrand']),
    frec_card_reposo: ['', Validators.required],
    resultadoT: [{ value: '', disabled: true }],
    resultadoA: [{ value: '', disabled: true }],
    resultadoK100: [{ value: '', disabled: true }],
    resultadoK90: [{ value: '', disabled: true }],
    resultadoK80: [{ value: '', disabled: true }],
    resultadoK70: [{ value: '', disabled: true }],
    resultadoK60: [{ value: '', disabled: true }]
  });

  ngOnInit() {
    this.httpClient.get('assets/json/salud/fcmax.json').subscribe(data => {
      this.test = data['fcmax'];
    });
  }

  calcularTest() {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.loading = true;

    this.apiTestService.FcmaxTest(this.dataForm.value).subscribe(res => {

      if (typeof(res.valores.fcm_karvonen) !== 'undefined') {
        this.k60 = res.valores.fcm_karvonen.intensidad60;
        this.k70 = res.valores.fcm_karvonen.intensidad70;
        this.k80 = res.valores.fcm_karvonen.intensidad80;
        this.k90 = res.valores.fcm_karvonen.intensidad90;
        this.k100 = res.valores.fcm_karvonen.intensidad100;
      } else {
        this.k60 = '';
        this.k70 = '';
        this.k80 = '';
        this.k90 = '';
        this.k100 = '';
      }

      if (typeof(res.valores.fcm_tanaka) !== 'undefined') {
        this.tanaka = res.valores.fcm_tanaka;
      } else {
        this.tanaka = '';
      }

      if (typeof(res.valores.fcm_astrand) !== 'undefined') {
        this.astrand = res.valores.fcm_astrand;
      } else {
        this.astrand = '';
      }

      this.loading = false;

      this.dataForm.patchValue({
        resultado: res.valores,
        resultadoT: this.tanaka,
        resultadoA: this.astrand,

        resultadoK100: this.k100,
        resultadoK90: this.k90,
        resultadoK80: this.k80,
        resultadoK70: this.k70,
        resultadoK60: this.k60
      });

    });
  }

  onCheckboxChange(e) {
    const formulas: FormArray = this.dataForm.get('formulas') as FormArray;

    if (e.target.checked) {
      formulas.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      formulas.controls.forEach((item: FormControl) => {
        if (item.value === e.target.value) {
          formulas.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

}
