<app-top-bar-nav class="col-12"></app-top-bar-nav>

<main role="main" class="container-fluid mt-5 pt-3">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="h4">Tests Aptitud Física</div>
      </div>
      <div class="row">
        <!--<div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" href="javascript:void(0)" (click)="goBack()" role="button">VOLVER.</a></div>-->
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/fms" role="button">FMS</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/push-up" role="button">Push Up</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/leg-press" role="button">Leg Press 1RM</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/cooper" role="button">Cooper Test</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/shuttle-run" role="button">4x10m Shuttle Run</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/gait-speed" role="button">Gait Speed</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/hand-grip" role="button">Hand Grip Force</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/yoyo2" role="button">YoYo Recovery Intermitent 2</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/sit-up" role="button">Sit Up</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/leg-power" role="button">Leg Power (> 60 Años)</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/long-jump" role="button">Long Jump</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/tug" role="button">Time Up and Go</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/chair-sit-and-reach" role="button">Chair sit and reach</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/rast" role="button">Running-based Anaerobic Sprint Test</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/bench-press-rm" role="button">Bench Press 1RM</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/navette" role="button">20m Shuttle Run</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/chair-stand" role="button">Chair Stand</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/two-minutes-step" role="button">2 Minutes Step</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/yoyo1" role="button">YoYo Recovery Intermitent 1</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/chair-sit-and-reach" role="button">Chair sit and reach</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/arm-curl" role="button">Arm Curl</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:white; color:black" routerLink="/private/categorias" role="button">IR A MENU</a></div>
      </div>
    </div>
</main>

  