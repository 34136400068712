<!-- Top navigation
<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-sm-3 col-md-2 mr-0" routerLink="/register-student">
    <img class="brand-logo" src="" alt="SportApp">
    <span class="dasboard-text">Dashboard</span>
  </a>
</nav>
-->

<!-- Sidebar navigation -->
<div class="container-fluid">
  <div class="row">

    <!-- Menu Lateral -->
    <!--<app-sidebar-nav></app-sidebar-nav>-->
    <app-top-bar-nav class="col-md-12 ml-sm-auto col-lg-12 px-4"></app-top-bar-nav>

    <!-- Main content -->
    <main role="main" class="col-md-12 ml-sm-auto col-lg-12 px-4">
      <div class="inner-adjust">

        <div class="pt-5 pb-2 mb-3 border-bottom">
          <h1 class="h2">Mis Evaluaciones</h1>
        </div>
        <!-- Show user data when logged in -->
        <div class="row" *ngIf="authService.userData as user">
          <div class="col-md-12">
            <h1 class="h3">{{(user.displayName) ? user.displayName : 'User'}}</h1>
            <!--<h2><strong>{{user.uid}}</strong></h2>-->
            <h2><strong>{{user.email}}</strong></h2>

            <!--
            <div class="media">
              <img class="align-self-start mr-5 img-thumbnail rounded-circle" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}"
                alt="{{user.displayName}}">
              <div class="media-body">
                <h1>Hola: <strong>{{(user.displayName) ? user.displayName : 'User'}}</strong></h1>
                <p>UID: <strong>{{user.uid}}</strong></p>
                <p>Email: <strong>{{user.email}}</strong></p>
                <p>Email Verificado?: <strong>{{user.emailVerified}}</strong></p>
              </div>
            </div>
            -->

            <div class="table-responsive">
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Test</th>
                    <th scope="col">Resultado</th>
                    <th scope="col">Valores</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor = "let row of arrayUsuarios">
                    <td>{{row.fecha}}</td>
                    <td>{{row.test}}</td>
                    <td>{{row.output.valores.clasificacion}}</td>
                    <td>{{row.output.valores.resultados | json}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>

  </div>
</div>
