import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ApiTestService } from '../../../../../shared/services/api-test.service';
import { GoogleAnalyticsService } from '../../../../../google-analytics.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-imc',
  templateUrl: './imc.component.html',
  styleUrls: ['./imc.component.css']
})
export class ImcComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title
  ) 
  { this.titleService.setTitle("SporTest App - Test - IMC"); }

  //g = localStorage.getItem('genero');
  //genero = (this.g !== '') ? this.g : '';

  categoria = 'Salud';
  test: any = [];
  semaforo = 'resultado';
  public loading: boolean = false;

  //u = localStorage.getItem('user');
  //uuid = this.u.uid;
  ////uuid = (this.u != null) ? String(this.u.uid) : '';

  dataForm = this.fb.group({
    uuid: [(localStorage.getItem('uid') !== null)?localStorage.getItem('uid'):''],
    categoria: [this.categoria],
    //genero: [this.genero, Validators.required],
    genero: ['', Validators.required],
    edad: ['', Validators.required],
    peso: ['', Validators.required],
    estatura: ['', Validators.required],
    imc: [{ value: '', disabled: true }],
    resultado: [{ value: '', disabled: true }]
  });

  ngOnInit() {
    this.httpClient.get('assets/json/salud/imc.json').subscribe(data => {
      this.test = data['imc'];
    });
  }

  calcularTest() {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.loading = true;

    //localStorage.setItem('genero', this.dataForm.value.genero);

    this.apiTestService.ImcTest(this.dataForm.value).subscribe(res => {

      this.loading = false;

      this.dataForm.patchValue({
        //imc: res.valores.imc,
        imc: res.valores.resultados.imc,
        resultado: res.valores.clasificacion
      });

      let valor = Number(res.valores.valor) || 0;
      
      if (valor == 1 || valor == 3 || valor == 4 || valor == 5 || valor == 6) {
        this.semaforo = 'resultado_rojo';
      } else if (valor == 2) {
        this.semaforo = 'resultado_verde';
      } else {
        this.semaforo = 'resultado';
      }

    });
  }

}
