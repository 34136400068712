import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ApiTestService } from '../../../../../shared/services/api-test.service';
import { GoogleAnalyticsService } from '../../../../../google-analytics.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-parq-and-you',
  templateUrl: './parq-and-you.component.html',
  styleUrls: ['./parq-and-you.component.css']
})
export class ParqAndYouComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title
  ) 
  { this.titleService.setTitle("SporTest App - Test - Par-Q & You"); }

  categoria = 'Salud';
  test: any = [];
  semaforo = 'resultado';
  public loading: boolean = false;


  dataForm = this.fb.group({
    uuid: [(localStorage.getItem('uid') !== null)?localStorage.getItem('uid'):''],
    categoria: [this.categoria],
    genero: ['', Validators.required],
    edad: ['', Validators.required],
    p1: ['', Validators.required],
    p2: ['', Validators.required],
    p3: ['', Validators.required],
    p4: ['', Validators.required],
    p5: ['', Validators.required],
    p6: ['', Validators.required],
    p7: ['', Validators.required],

    resultado: [{ value: '', disabled: true }]
  });

  ngOnInit() {
    this.httpClient.get('assets/json/salud/parq.json').subscribe(data => {
      this.test = data['parq'];
    });
  }

  calcularTest() {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.loading = true;

    this.apiTestService.ParqAndYouTest(this.dataForm.value).subscribe(res => {

      this.loading = false;

      this.dataForm.patchValue({
        resultado: res.valores.resultado
      });
      
      let valor = Number(res.valores.valor) || 0;
      
      if (valor == 1) {
        this.semaforo = 'resultado_rojo';
      } else if (valor == 2) {
        this.semaforo = 'resultado_verde';
      } else {
        this.semaforo = 'resultado';
      }
      
    });
  }

}
