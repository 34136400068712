import { Injectable, NgZone } from '@angular/core';
import { User } from './user';
import { auth } from 'firebase/app';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';

import { ResponseListadoTest } from './response-listado-test';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any;
  userDataAdd: any;
  userId: any;

  constructor(
    public angularFirestore: AngularFirestore, 
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
  ) {

    console.log("VALIDAMOS SESION USUARIO");
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        
        console.log("Sesión Iniciada");
        console.log("UID: ", this.userData.uid);
        console.log("EMAIL: ", this.userData.email);

        localStorage.setItem('user', this.userData);
        localStorage.setItem('uid', this.userData.uid);
        
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
      } 
      /*else {
        console.log("Sesión No Iniciada");
        this.SignOut();
      }*/
    });
  }

  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        //this.SetUserData(result.user);
        console.log(result.user);
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
      }).catch((error) => {
        console.log(error.message);
      });
  }

  SignUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.SendVerificationMail();
        //this.SetUserData(result.user);
        this.AddUserData(result.user);
      }).catch((error) => {
        console.log(error.message);
      });
  }

  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email']);
    });
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      console.log('Se ha enviado un email para recuperar su password, revise su bandeja de entrada.');
      this.router.navigate(['login']);
    }).catch((error) => {
      console.log(error);
    });
  }

  get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    return (user !== null) ? true : false;
    // return (user !== null && user.emailVerified !== false) ? true : false;
  }

  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  /*
  FacebookAuth() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }

  TwitterAuth() {
    return this.AuthLogin(new auth.TwitterAuthProvider());
  }
  */

  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
      console.log("Usuario: ");
      console.log(result.user);
      this.AddUserData(result.user);
      //this.SetUserData(result.user);
      this.userData = result.user;
      localStorage.setItem('user', this.userData);
      localStorage.setItem('uid', this.userData.uid);
      this.ngZone.run(() => {
        this.router.navigate(['dashboard']);
      });
      //this.SetUserData(result.user);
    }).catch((error) => {
      console.log(error);
    });
  }

  /*
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.angularFirestore.doc(`usuarios/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      phoneNumber: user.phoneNumber,
      nombres: '',
      apellidos: '',
      genero: (localStorage.getItem('genero') !== null)? localStorage.getItem('genero'): '',
      fecha_nacimiento: '',
      ciudad: '',
      direccion: '',
      providerId: user.providerData[0].providerId
    };
    return userRef.set(userData, {
      merge: true
    });
  }
  */

  ListTestByUser(uid) {
    const testsUserRef = this.angularFirestore.firestore.collection(`usuarios/${uid}/tests`);

    testsUserRef
    .get()
    .then((results) => {
      const data = results.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Tests del usuario: ", data); 
    });
  }

  DeleteTestById(uid, idTest) {
    const testRef = this.angularFirestore.firestore.collection(`usuarios/${uid}/tests`);

    testRef.doc(idTest)
    .delete()
    .then((res) => {
      console.log(res);
      console.log("Documento borrado")
    })
    .catch((error) => console.error("Error eliminando documento", error));
  }

  AddUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.angularFirestore.doc(`usuarios/${user.uid}`);
    const userDataAdd: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      phoneNumber: user.phoneNumber,
      nombres: '',
      apellidos: '',
      genero: '',
      fecha_nacimiento: '',
      ciudad: '',
      direccion: '',
      providerId: user.providerData[0].providerId
    };

    return this.angularFirestore.collection('usuarios').doc(user.uid).set(userDataAdd);
  }

  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('uid');
      this.router.navigate(['login']);
    });
  }

}
