<!-- Sidebar navigation -->
<div class="container-fluid">
    <div class="row">
  
      <!-- Menu Lateral -->
      <!--<app-sidebar-nav></app-sidebar-nav>-->
      <app-top-bar-nav class="col-md-12 ml-sm-auto col-lg-12 px-4"></app-top-bar-nav>
  
      <!-- Main content -->
      <main role="main" class="col-md-12 ml-sm-auto col-lg-12 px-4">
        <div class="inner-adjust">
  
          <div class="pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Categorías</h1>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#669933" routerLink="/private/salud" role="button">Salud</a></div>
              <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#18B5D0" routerLink="/private/antropometria" role="button">Antropometria</a></div>
              <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#FF6633" routerLink="/private/aptitud-fisica" role="button">Aptitud Física</a></div>
              <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:white; color:black" routerLink="/dashboard" role="button">IR A DASHBOARD</a></div>
            </div>
          </div>
  
        </div>
      </main>
  
    </div>
  </div>
  