<app-top-bar-nav class="col-12"></app-top-bar-nav>

<main role="main" class="container-fluid mt-5 pt-3">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="h4">Tests Antropometría</div>
      </div>
      <div class="row">
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#18B5D0" routerLink="/private/fp-jp-seven" role="button">% Grasa Corporal Jackson & Pollock</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#18B5D0" routerLink="/private/fp-slaughter" role="button" >% Grasa Corporal Slaughter</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#18B5D0" routerLink="/private/fp-durnin-womersley" role="button">% Grasa Corporal Durnin-Womersley</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#18B5D0" routerLink="/private/fp-carter" role="button">% Grasa Corporal Carter</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#18B5D0" routerLink="/private/fp-faulkner" role="button">% Grasa Corporal Faulkner</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#18B5D0" routerLink="/private/fp-weltman" role="button">% Grasa Corporal Weltman</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:white; color:black" routerLink="/private/categorias" role="button">IR A MENU</a></div>
      </div>
    </div>
</main>