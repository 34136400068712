<!-- Sidebar navigation -->
<!-- <div class="container-fluid"> -->
<!--  <div class="row"> -->

    <nav class="col-md-2 d-md-block bg-bg-dark sidebar">
      <div class="sidebar-sticky">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link active" routerLink="/dashboard">
              <i class="fas fa-angle-right"></i><b>Dashboard</b>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/profile">
              <i class="fas fa-user"></i>Perfil Usuario
            </a>
          </li>
          <li class="nav-item">
              <i class="fas fa-angle-right"></i><b>Antropometría</b>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/imc">
              <i class="fas fa-sign-out-alt"></i>Test IMC
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/densidad-corporal-jyp">
              <i class="fas fa-sign-out-alt"></i>Test Jackson y Pollock
            </a>
          </li>

          <li class="nav-item">
              <i class="fas fa-angle-right"></i><b>Fcmax</b>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/tanaka">
              <i class="fas fa-sign-out-alt"></i>Test Tanaka
            </a>
          </li>

          <li class="nav-item">
              <i class="fas fa-angle-right"></i><b>Fuerza Muscular</b>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/pushup">
              <i class="fas fa-sign-out-alt"></i>Test PushUp
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/situp-general">
              <i class="fas fa-sign-out-alt"></i>Test SitUp General
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/situp-old">
              <i class="fas fa-sign-out-alt"></i>Test SitUp Adulto Mayor
            </a>
          </li>
          <li class="nav-item">
              <i class="fas fa-angle-right"></i><b>Evaluaciones</b>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/auto-evaluacion">
              <i class="fas fa-sign-out-alt"></i>Crear Autoevaluación
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/crear-evaluacion">
              <i class="fas fa-sign-out-alt"></i>Crear Evaluación
            </a>
          </li>
          <!--
          <li class="nav-item">
            <a class="nav-link" routerLink="/ejemplos">
              <i class="fas fa-sign-out-alt"></i>Ejemplos
            </a>
          </li>
          -->
          <!-- Calling SignOut() Api from AuthService -->
          <li class="nav-item">
            <a class="nav-link" (click)="authService.SignOut()">
              <i class="fas fa-sign-out-alt"></i><b>Salir</b>
            </a>
          </li>
        </ul>
      </div>
    </nav>
<!--   </div> -->
<!-- </div> -->
