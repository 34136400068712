import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from "@angular/router";

import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'app-top-bar-nav',
  templateUrl: './top-bar-nav.component.html',
  styleUrls: ['./top-bar-nav.component.css']
})
export class TopBarNavComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone
  ) { }

  ngOnInit() {
  }

}
