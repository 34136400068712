import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bruce',
  templateUrl: './bruce.component.html',
  styleUrls: ['./bruce.component.css']
})
export class BruceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
