import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare const gtag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-90720LNCTH',
            {
              'page_path': event.urlAfterRedirects
            }
        );
      }
    });

    /*
    if (localStorage.getItem('genero') === null) {
      localStorage.setItem('genero', '');
    }
    if (localStorage.getItem('edad') === null) {
      localStorage.setItem('edad', '');
    }
    */
  }
}
