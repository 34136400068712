import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ApiTestService } from '../../../../../shared/services/api-test.service';
import { GoogleAnalyticsService } from '../../../../../google-analytics.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-push-up',
  templateUrl: './push-up.component.html',
  styleUrls: ['./push-up.component.css']
})
export class PushUpComponent implements OnInit {

  categoria = 'Aptitud Física';
  semaforo = 'resultado';
  loading: boolean = false;
  test: any = [];


  dataForm = this.fb.group({
    uuid: [(localStorage.getItem('uid') !== null)?localStorage.getItem('uid'):''],
    categoria: [this.categoria],
    genero: ['', Validators.required],
    edad: ['', Validators.required],
    repeticiones: ['', Validators.required],
    resultado: [{ value: '', disabled: true }]
  });

  constructor(
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title
  ) 
  { this.titleService.setTitle("SporTest App - Test - Push Up"); }


  ngOnInit() {
    this.httpClient.get('assets/json/aptitud-fisica/push-up.json').subscribe(data => {
      this.test = data['push_up'];
    });
  }

  calcularTest() {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.loading = true;

    this.apiTestService.PushUpTest(this.dataForm.value).subscribe(res => {

      this.loading = false;

      this.dataForm.patchValue({
        resultado: res.valores.clasificacion
      });

      let valor = Number(res.valores.valor) || 0;
      
      if (valor == 5 || valor == 4) {
        this.semaforo = 'resultado_verde';
      } else if (valor == 3) {
        this.semaforo = 'resultado_amarillo';
      } else if (valor == 2 || valor == 1) {
        this.semaforo = 'resultado_rojo';
      } else {
        this.semaforo = 'resultado';
      }
    });
  }

}
