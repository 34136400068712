import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { ApiTestService } from '../../../../../shared/services/api-test.service';
import { GoogleAnalyticsService } from '../../../../../google-analytics.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-fp-withers',
  templateUrl: './fp-withers.component.html',
  styleUrls: ['./fp-withers.component.css']
})
export class FpWithersComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title
  ) 
  { this.titleService.setTitle("SporTest App - Test - Porcentaje Grasa (Withers)"); }

  categoria = 'Antropometria';
  test: any = [];
  semaforo = 'resultado';
  public loading: boolean = false;

  dataForm = this.fb.group({
    uuid: [(localStorage.getItem('uid') !== null)?localStorage.getItem('uid'):''],
    categoria: [this.categoria],
    genero: ['', Validators.required],
    peso: ['', Validators.required],
    edad: ['', Validators.required],
    pliegue_triceps: ['', Validators.required],
    pliegue_subescapular: ['', Validators.required],
    pliegue_supraespinal: ['', Validators.required],
    pliegue_pierna_medial: ['', Validators.required],
    perimetro_mesoesternal: ['', Validators.required],
    perimetro_gluteo: ['', Validators.required],
    perimetro_cintura: ['', Validators.required],
    altura: ['', Validators.required],


    porcentaje_grasa: [{ value: '', disabled: true }],
    masa_grasa: [{ value: '', disabled: true }],
    masa_magra: [{ value: '', disabled: true }],
    resultado: [{ value: '', disabled: true }]
  });

  ngOnInit() {
    this.httpClient.get('assets/json/antropometria/withers.json').subscribe(data => {
      this.test = data['withers'];
    });
  }

  calcularTest() {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.loading = true;

    this.apiTestService.FpWithersTest(this.dataForm.value).subscribe(res => {

      this.loading = false;

      this.dataForm.patchValue({
        porcentaje_grasa: res.valores.resultados.porcentaje_grasa,
        masa_grasa: res.valores.resultados.masa_grasa,
        masa_magra: res.valores.resultados.masa_magra,
        resultado: res.valores.clasificacion
      });

      let valor = Number(res.valores.valor) || 0;
      
      if (valor == 1 || valor == 2) {
        this.semaforo = 'resultado_rojo';
      } else if (valor == 3) {
        this.semaforo = 'resultado_amarillo';
      } else if (valor == 4 || valor == 5 || valor == 6) {
        this.semaforo = 'resultado_verde';
      } else {
        this.semaforo = 'resultado';
      }
      
    });
  }

}
