import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categoria-aptitud-fisica',
  templateUrl: './categoria-aptitud-fisica.component.html',
  styleUrls: ['./categoria-aptitud-fisica.component.css']
})
export class CategoriaAptitudFisicaComponent implements OnInit {

  constructor(private location: Location, public router: Router) { }

  ngOnInit(): void {
  }

  goBack() {
    if (this.location.path().toString() === String('/private/aptitud-fisica')){
      console.log( 'ROb' );
      this.location.back();
    }else {
      this.router.navigate(['login']);
    }
    // window.history.back();
    this.location.back();
    console.log(this.location.path());
    console.log( 'goBack()...' );
  }

}
