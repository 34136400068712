<div class="displayTable">
  <div class="displayTableCell">

    <div class="px-logo">
      <a href="https://www.sportest.io" target="_blank">
        <img src="" alt="">
      </a>
    </div>

    <div class="authBlock">
      <div class="formGroup" style="text-align: center;">
        <img src="/assets/img/sportest_horizontal_blog_small.png" width="132" height="44" class="d-inline-block align-top" alt="">
      </div>

      <h3>Gracias por registrarte</h3>

      <div class="formGroup" *ngIf="authService.userData as user">
        <p class="text-center">Hemos enviado un correo electrónico de confirmación a <strong>{{user.email}}</strong>.</p>
        <p class="text-center">Verifique su correo electrónico y haga clic en el enlace para verificar su dirección de correo electrónico.</p>
      </div>

      <!-- Calling SendVerificationMail() method using authService Api -->
      <div class="formGroup">
        <button type="button" class="btn btnPrimary" (click)="authService.SendVerificationMail()">
          <i class="fas fa-redo-alt"></i>
          Reenviar correo electrónico de verificación
        </button>
      </div>

    </div>

    <div class="redirectToLogin">
      <span>Ir al <span class="redirect" routerLink="/login"><b>LOGIN</b></span></span>
    </div>

  </div>
</div>
