<nav class="navbar navbar-light bg-light navbar-expand-md navbar-expand-lg navbar-expand-xl">
  <div class="container-fluid" style="background-color: white;">
    <a class="navbar-brand ml-3" href="https://sportest.io" target="_blank">
      <img src="/assets/img/sportest_horizontal_blog_small.png" width="132" height="44" class="d-inline-block align-top" alt="">
    </a>

    <button *ngIf="authService.isLoggedIn" class="navbar-toggler custom-toggler mr-1" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse mr-1 ml-1 pb-1" id="navbarNavDropdown" *ngIf="authService.isLoggedIn">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link pt-1 pb-1 btn btn-primary" routerLink="/dashboard" style="color: black; background-color: #f6f6f6; border-color: black;"><b>Mis Evaluaciones</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link pt-1 pb-1 btn btn-primary" routerLink="/private/categorias" style="color: black; background-color: #f6f6f6; border-color: black"><b>Evaluaciones</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link pt-1 pb-1 btn btn-primary" (click)="authService.SignOut()" style="color: red; background-color: #f6f6f6; border-color: black"><b>Salir</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fluid">
  <a *ngIf="!authService.isLoggedIn" class="nav-link pt-1 pb-1 mb-3 btn btn-primary" routerLink="/login" style="color: red; background-color: #f6f6f6; border-color: black;"><b>Iniciar Sesion O Crear Cuenta</b></a>
</div>
