import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import canActivate guard services
import { AuthGuard } from '../../shared/guard/auth.guard';
import { SecureInnerPagesGuard } from '../../shared/guard/secure-inner-pages.guard';

// Auth y perfil usuario
import { SignInComponent } from '../../components/auth/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/auth/sign-up/sign-up.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../../components/auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../../components/auth/verify-email/verify-email.component';
import { ProfileComponent } from '../../components/auth/profile/profile.component';

// Public
// Salud
import { FcmaxComponent } from '../../components/tests/public/salud/fcmax/fcmax.component';
import { ImcComponent } from '../../components/tests/public/salud/imc/imc.component';
import { MmaeSarcopeniaComponent } from '../../components/tests/public/salud/mmae-sarcopenia/mmae-sarcopenia.component';
import { ParqAndYouComponent } from '../../components/tests/public/salud/parq-and-you/parq-and-you.component';
import { WaistPerimeterComponent } from '../../components/tests/public/salud/waist-perimeter/waist-perimeter.component';
import { GastocaloricoComponent } from '../../components/tests/public/salud/gastocalorico/gastocalorico.component';

// Antropometria
import { FpCarterComponent } from '../../components/tests/public/antropometria/fp-carter/fp-carter.component';
import { FpDurninWomersleyComponent } from '../../components/tests/public/antropometria/fp-durnin-womersley/fp-durnin-womersley.component';
import { FpFaulknerComponent } from '../../components/tests/public/antropometria/fp-faulkner/fp-faulkner.component';
import { FpJPSevenPComponent } from '../../components/tests/public/antropometria/fp-j-p-seven-p/fp-j-p-seven-p.component';
import { FpJPThreePComponent } from '../../components/tests/public/antropometria/fp-j-p-three-p/fp-j-p-three-p.component';
import { FpSlaughterComponent } from '../../components/tests/public/antropometria/fp-slaughter/fp-slaughter.component';
import { FpWeltmanComponent } from '../../components/tests/public/antropometria/fp-weltman/fp-weltman.component';

// Aptitud Fisica
import { ArmCurlComponent } from '../../components/tests/public/aptitud-fisica/arm-curl/arm-curl.component';
import { BenchPressRmComponent } from '../../components/tests/public/aptitud-fisica/bench-press-rm/bench-press-rm.component';
import { BenchPressFmComponent } from '../../components/tests/public/aptitud-fisica/bench-press-fm/bench-press-fm.component';
import { BruceComponent } from '../../components/tests/public/aptitud-fisica/bruce/bruce.component';
import { ChairSitAndReachComponent } from '../../components/tests/public/aptitud-fisica/chair-sit-and-reach/chair-sit-and-reach.component';
import { ChairStandComponent } from '../../components/tests/public/aptitud-fisica/chair-stand/chair-stand.component';
import { CooperComponent } from '../../components/tests/public/aptitud-fisica/cooper/cooper.component';
import { CourseNavetteSrtComponent } from '../../components/tests/public/aptitud-fisica/course-navette-srt/course-navette-srt.component';
import { GaitSpeedComponent } from '../../components/tests/public/aptitud-fisica/gait-speed/gait-speed.component';
import { HandGripComponent } from '../../components/tests/public/aptitud-fisica/hand-grip/hand-grip.component';
import { LegPowerComponent } from '../../components/tests/public/aptitud-fisica/leg-power/leg-power.component';
import { LegPressOneRmComponent } from '../../components/tests/public/aptitud-fisica/leg-press-one-rm/leg-press-one-rm.component';
import { LongJumpFeetComponent } from '../../components/tests/public/aptitud-fisica/long-jump-feet/long-jump-feet.component';
import { PushUpComponent } from '../../components/tests/public/aptitud-fisica/push-up/push-up.component';
import { RastComponent } from '../../components/tests/public/aptitud-fisica/rast/rast.component';
import { ShuttleRunComponent } from '../../components/tests/public/aptitud-fisica/shuttle-run/shuttle-run.component';
import { SitUpComponent } from '../../components/tests/public/aptitud-fisica/sit-up/sit-up.component';
import { TugComponent } from '../../components/tests/public/aptitud-fisica/tug/tug.component';
import { TwoMinutesStepComponent } from '../../components/tests/public/aptitud-fisica/two-minutes-step/two-minutes-step.component';
import { UmttComponent } from '../../components/tests/public/aptitud-fisica/umtt/umtt.component';
import { YoyoIrOneComponent } from '../../components/tests/public/aptitud-fisica/yoyo-ir-one/yoyo-ir-one.component';
import { YoyoIrTwoComponent } from '../../components/tests/public/aptitud-fisica/yoyo-ir-two/yoyo-ir-two.component';
import { FmsComponent } from '../../components/tests/public/aptitud-fisica/fms/fms.component';
import { RuffierComponent } from '../../components/tests/public/aptitud-fisica/ruffier/ruffier.component';
import { CategoriasComponent } from '../../components/sportest/categorias/categorias.component';
import { CategoriaSaludComponent } from '../../components/sportest/categoria-salud/categoria-salud.component';
import { CategoriaAntropometriaComponent } from '../../components/sportest/categoria-antropometria/categoria-antropometria.component';
import { CategoriaAptitudFisicaComponent } from '../../components/sportest/categoria-aptitud-fisica/categoria-aptitud-fisica.component';




// rutas en router
const routes: Routes = [
  //{ path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  //{ path: '', redirectTo: '/login', pathMatch: 'full'},
  //{ path: 'login', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'login', component: SignInComponent},
  //{ path: 'register', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  //{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent},
  //{ path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  //{ path: 'verify-email', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] },
  //{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },

  
  // public aptitud-fisica
  { path: 'public/arm-curl', component: ArmCurlComponent },
  { path: 'public/bench-press-rm', component: BenchPressRmComponent },
  { path: 'public/bench-press-fm', component: BenchPressFmComponent },
  { path: 'public/bruce', component: BruceComponent },
  { path: 'public/chair-sit-and-reach', component: ChairSitAndReachComponent },
  { path: 'public/chair-stand', component: ChairStandComponent },
  { path: 'public/cooper', component: CooperComponent },
  { path: 'public/navette', component: CourseNavetteSrtComponent },
  { path: 'public/gait-speed', component: GaitSpeedComponent },
  { path: 'public/hand-grip', component: HandGripComponent },
  { path: 'public/leg-power', component: LegPowerComponent },
  { path: 'public/leg-press', component: LegPressOneRmComponent },
  { path: 'public/long-jump', component: LongJumpFeetComponent },
  { path: 'public/push-up', component: PushUpComponent },
  { path: 'public/rast', component: RastComponent },
  { path: 'public/shuttle-run', component: ShuttleRunComponent },
  { path: 'public/sit-up', component: SitUpComponent },
  { path: 'public/tug', component: TugComponent },
  { path: 'public/two-minutes-step', component: TwoMinutesStepComponent },
  { path: 'public/umtt', component: UmttComponent },
  { path: 'public/yoyo1', component: YoyoIrOneComponent },
  { path: 'public/yoyo2', component: YoyoIrTwoComponent },
  { path: 'public/fms', component: FmsComponent },
  { path: 'public/ruffier', component: RuffierComponent },

  // public salud
  { path: 'public/fcmax', component: FcmaxComponent },
  { path: 'public/imc', component: ImcComponent },
  { path: 'public/mmae-sarcopenia', component: MmaeSarcopeniaComponent },
  { path: 'public/parq', component: ParqAndYouComponent },
  { path: 'public/waist-perimeter', component: WaistPerimeterComponent },
  { path: 'public/gastocalorico', component: GastocaloricoComponent },

  // public antropometria
  { path: 'public/fp-carter', component: FpCarterComponent },
  { path: 'public/fp-durnin-womersley', component: FpDurninWomersleyComponent },
  { path: 'public/fp-faulkner', component: FpFaulknerComponent },
  { path: 'public/fp-jp-seven', component: FpJPSevenPComponent },
  { path: 'public/fp-jp-three', component: FpJPThreePComponent },
  { path: 'public/fp-slaughter', component: FpSlaughterComponent },
  { path: 'public/fp-weltman', component: FpWeltmanComponent },

  ///////////////////////////////////////////////////////////////////////
  // private
  { path: 'private/categorias', component: CategoriasComponent, canActivate: [AuthGuard] },
  { path: 'private/salud', component: CategoriaSaludComponent, canActivate: [AuthGuard] },
  { path: 'private/antropometria', component: CategoriaAntropometriaComponent, canActivate: [AuthGuard] },
  { path: 'private/aptitud-fisica', component: CategoriaAptitudFisicaComponent, canActivate: [AuthGuard] },

  // Private Salud
  { path: 'private/fcmax', component: FcmaxComponent },
  { path: 'private/imc', component: ImcComponent },
  { path: 'private/mmae-sarcopenia', component: MmaeSarcopeniaComponent },
  { path: 'private/parq', component: ParqAndYouComponent },
  { path: 'private/waist-perimeter', component: WaistPerimeterComponent },
  { path: 'private/gastocalorico', component: GastocaloricoComponent },

  // Private Antropometria
  { path: 'private/fp-carter', component: FpCarterComponent },
  { path: 'private/fp-durnin-womersley', component: FpDurninWomersleyComponent },
  { path: 'private/fp-faulkner', component: FpFaulknerComponent },
  { path: 'private/fp-jp-seven', component: FpJPSevenPComponent },
  { path: 'private/fp-jp-three', component: FpJPThreePComponent },
  { path: 'private/fp-slaughter', component: FpSlaughterComponent },
  { path: 'private/fp-weltman', component: FpWeltmanComponent },

  // Private Aptitud Física
  { path: 'private/arm-curl', component: ArmCurlComponent },
  { path: 'private/bench-press-rm', component: BenchPressRmComponent },
  { path: 'private/bench-press-fm', component: BenchPressFmComponent },
  { path: 'private/bruce', component: BruceComponent },
  { path: 'private/chair-sit-and-reach', component: ChairSitAndReachComponent },
  { path: 'private/chair-stand', component: ChairStandComponent },
  { path: 'private/cooper', component: CooperComponent },
  { path: 'private/navette', component: CourseNavetteSrtComponent },
  { path: 'private/gait-speed', component: GaitSpeedComponent },
  { path: 'private/hand-grip', component: HandGripComponent },
  { path: 'private/leg-power', component: LegPowerComponent },
  { path: 'private/leg-press', component: LegPressOneRmComponent },
  { path: 'private/long-jump', component: LongJumpFeetComponent },
  { path: 'private/push-up', component: PushUpComponent },
  { path: 'private/rast', component: RastComponent },
  { path: 'private/shuttle-run', component: ShuttleRunComponent },
  { path: 'private/sit-up', component: SitUpComponent },
  { path: 'private/tug', component: TugComponent },
  { path: 'private/two-minutes-step', component: TwoMinutesStepComponent },
  { path: 'private/umtt', component: UmttComponent },
  { path: 'private/yoyo1', component: YoyoIrOneComponent },
  { path: 'private/yoyo2', component: YoyoIrTwoComponent },
  { path: 'private/fms', component: FmsComponent },
  { path: 'private/ruffier', component: RuffierComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
