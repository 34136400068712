<app-top-bar-nav class="col-12"></app-top-bar-nav>

<main role="main" class="container-fluid mt-5 pt-3">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="h4">Tests Salud</div>
      </div>
      <div class="row">
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#669933" routerLink="/private/imc" role="button">IMC</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#669933" routerLink="/private/fcmax" role="button" >FCMax</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#669933" routerLink="/private/mmae-sarcopenia" role="button">MMAE Sarcopenia</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#669933" routerLink="/private/gastocalorico" role="button">Gasto calórico</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#669933" routerLink="/private/waist-perimeter" role="button">Peímetro de Cintura</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:#669933" routerLink="/private/parq" role="button">Par-Q & You</a></div>
        <div class="col-12 col-xs-6 col-md-4 col-lg-3 pt-1"><a class="btn btn-primary btn-block" style="background-color:white; color:black" routerLink="/private/categorias" role="button">IR A MENU</a></div>
      </div>
    </div>
</main>
  