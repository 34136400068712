<div class="container-fluid">
  <div class="row">

    <!-- Menu Lateral -->
    <app-sidebar-nav></app-sidebar-nav>

    <!-- Main content -->
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div class="inner-adjust">

        <div class="pt-3 pb-2 mb-3 border-bottom">
          <h2 class="h2">Perfil Usuario</h2>
        </div>



      </div>
    </main>
  </div>
</div>
