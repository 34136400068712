<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <div class="formGroup" style="text-align: center;">
        <img src="/assets/img/sportest_horizontal_blog_small.png" width="132" height="44" class="d-inline-block align-top" alt="">
      </div>

      <h3>Restablecer Contraseña</h3>

      <p class="text-center">Ingrese su dirección de correo electrónico para solicitar un restablecimiento de contraseña.</p>

      <div class="formGroup">
        <input type="email" class="formControl" placeholder="Email" #passwordResetEmail required>
      </div>

      <!-- Calling ForgotPassword from AuthService Api -->
      <div class="formGroup">
        <input type="submit" class="btn btnPrimary" value="Restablecer Contraseña" (click)="authService.ForgotPassword(passwordResetEmail.value)">
      </div>
    </div>

    <div class="redirectToLogin">
      <span>Ir al <span class="redirect" routerLink="/login"><b>LOGIN</b></span></span>
    </div>

  </div>
</div>