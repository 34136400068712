import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { GoogleAnalyticsService } from '../../google-analytics.service';
import { FormBuilder, FormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ApiTestService } from '../../shared/services/api-test.service';
import { Title } from "@angular/platform-browser";
import { DbService } from '../../shared/services/db.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  categoria = 'Listado Test';
  testUsuarios: any = [];
  arrayUsuarios: any = [];
  usuarioId:any;
  usuarioData: any;

  allUsers: any = [];
  allUsers2: any = [];
  allUsers3: any = [];
  usuario: any = [];
  testsUser: any = [];

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    private fb: FormBuilder,
    private apiTestService: ApiTestService,
    private sanitized: DomSanitizer,
    private httpClient: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService:Title,
    private dbService: DbService,
  ) { 
    console.log("CARGANDO DASHBOARD EN CONSTRUCTOR");
    //this.getListTestsByUser(localStorage.getItem('uid'));

  }



  ngOnInit() {
    this.getListTestsByUser(localStorage.getItem('uid'));
    this.authService.ListTestByUser(localStorage.getItem('uid'));
   }

   async getUsers() {
    this.allUsers = await this.dbService.getAllUsers();
   }

   async getUsers2() {
    this.allUsers2 = await this.dbService.getAllUsers2();
   }

   async getUsers3() {
    this.allUsers3 = await this.dbService.getAllUsers3();
   }

  async getUserById(uid) {
    this.usuario = await this.dbService.getUserById(uid);
  }


  
  async getListTestsByUser(uuid): Promise<void> {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    let datos = {
      collection: "usuarios",
      uuid: uuid
    }; 

    this.testUsuarios = this.dbService.getListTestByUser(datos).subscribe(res => {

      for (var clave in res) {
        this.arrayUsuarios.push(res[clave]);
      }
      console.log(this.arrayUsuarios);

    });

  }
  
  //solo de prueba
  async getListTestsByUser2(uuid): Promise<void> {
    this.googleAnalyticsService.eventEmitter(this.constructor.name, this.categoria);

    this.usuario = this.dbService.getListadoTestUsuario(uuid);

    console.log("111");
    console.log(this.usuario);
    console.log("222");

  }  

}
