import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseListadoTest } from './response-listado-test';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  //Prod
  baseurl = 'https://serv-dev-dot-sportest-a1588.ue.r.appspot.com/';

  //Testing
  //baseurl = "https://testing-dot-sportest-a1588.ue.r.appspot.com/"

  constructor(private db: AngularFirestore, private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private handleError(error: any): Promise<any> {
    console.error('Ocurrió un error', error);
    return Promise.reject(error.message || error);
  }

  getAllUsers() {
    return new Promise<any>((resolve)=> {
    this.db.collection('usuarios').valueChanges({ idField: 'id' }).subscribe(users => resolve(users));
    })
  }

  getAllUsers2() {
    return new Promise<any>((resolve)=> {
    this.db.collection('usuarios').valueChanges().subscribe(users => resolve(users));
    })
  }

  getAllUsers3() {
    return new Promise<any>((resolve)=> {
    this.db.collection('usuarios').snapshotChanges().subscribe(users => resolve(users));
    })
  }

  //Data Firestore
  getUserById(_id:any) {
    return new Promise<any>((resolve)=> {
    this.db.collection('usuarios', ref => ref.where('uid', '==', _id)).valueChanges().subscribe(usuario => resolve(usuario))
    })
  }

  //Data de API Python
  getListTestByUser(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'list-test', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getListadoTestUsuario(_id:any) {
    return new Promise<any>((resolve)=> {
    this.db.collection('usuarios', ref => ref.where('uid', '==', _id)).valueChanges().subscribe(usuario => resolve(usuario))
    })
  }

}
