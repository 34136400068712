<app-top-bar-nav></app-top-bar-nav>
<hr>
<div class="container-fluid">
  <div class="row d-flex justify-content-md-center pt-0">

    <div class="col-12 col-md-6 col-lg-6 text-center p-0">
      <div class="table-responsive-sm">
        <table class="table table-sm table-borderless">
          <tbody>
            <tr>
              <td class="titulo_en text_cell_14 {{ test.color }}"><strong>{{ test.nombre_en }}</strong></td>
              <td><i class="{{ test.icono }} {{ test.color }} icono_test"></i></td>
              <td class="titulo_es text_cell_14"><strong>{{ test.nombre_es }}</strong></td>
            </tr>
            <tr>
              <td colspan="3" class="text_cell_12" style="text-align: center;"><i>{{ test.descripcion }}<br>{{ test.autor }}</i></td>
            </tr>
        </table>
        <hr>
  
          <form [formGroup]="dataForm" class="noscroll">
              <input type="hidden" formControlName="categoria">
            <input type="hidden" formControlName="uuid">
  
              <div class="form-group">
                  <!--<label><b>Género</b></label>-->
                  <div class="generos">
                    <i class="fa fa-female figura_mujer"></i>
                    <input type="radio" id="idGeneroF" value="Femenino" name="genero" formControlName="genero">
                    <!--<label for="idGeneroF">Femenino</label>-->
                  </div>
  
                  <div class="generos">
                    <i class="fa fa-male figura_hombre"></i>
                    <input type="radio" id="idGeneroM" value="Masculino" name="genero" formControlName="genero">
                    <!--<label for="idGeneroM">Masculino</label>-->
                  </div>
              </div>
  
              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Dato</th>
                      <th scope="col">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text_cell_14">Edad (años)</td>
                      <td><input type="number" style="width: 120px;" class="text_center" placeholder="[5 - 120]" formControlName="edad" required /></td>
                    </tr>
                    <tr>
                        <td class="text_cell_14">Peso (Kg)</td>
                        <td><input type="number" style="width: 120px;" class="text_center" placeholder="60" formControlName="peso" required /></td>
                      </tr>                    
                    <tr>
                      <td class="text_cell_14">Pliegue Triceps (mm)</td>
                      <td><input type="number" style="width: 120px;" class="text_center" placeholder="12" formControlName="pliegue_triceps" required /></td>
                    </tr>
                    <tr>
                      <td class="text_cell_14">Pliegue Biceps (mm)</td>
                      <td><input type="number" style="width: 120px;" class="text_center" placeholder="16" formControlName="pliegue_biceps" required /></td>
                    </tr>
                    <tr>
                      <td class="text_cell_14">Pliegue Subescapular (mm)</td>
                      <td><input type="number" style="width: 120px;" class="text_center" placeholder="16" formControlName="pliegue_subescapular" required /></td>
                    </tr>
                    <tr>
                      <td class="text_cell_14">Pliegue Ileocrestal (mm)</td>
                      <td><input type="number" style="width: 120px;" class="text_center" placeholder="16" formControlName="pliegue_ileocrestal" required /></td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text_cell_14">
                        <button type="button" class="btn btn-primary btn-md btn-block" id="idCalcular" (click)="calcularTest()"
                          [disabled]="!dataForm.valid">
                        Calcular
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                      </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <span class="input-group-text width_clasificacion" id="inputGroup-sizing-default"><b>% GRASA</b></span>
                          </div>
                          <input type="text" id="resultado" formControlName="porcentaje_grasa" placeholder="PORCENTAJE GRASA" class="form-control text-center" aria-label="PORCENTAJE GRASA" aria-describedby="inputGroup-sizing-default">
                          <div class="input-group-append">
                            <span class="input-group-text"><b>%</b></span>
                          </div>
                        </div>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text width_clasificacion" id="inputGroup-sizing-default"><b>MASA GRASA</b></span>
                            </div>
                            <input type="text" id="resultado" formControlName="masa_grasa" placeholder="MASA GRASA" class="form-control text-center" aria-label="MASA GRASA" aria-describedby="inputGroup-sizing-default">
                            <div class="input-group-append">
                              <span class="input-group-text"><b>Kg</b></span>
                            </div>
                        </div>

                        <!--
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text width_clasificacion" id="inputGroup-sizing-default"><b>MASA MAGRA</b></span>
                            </div>
                            <input type="text" id="resultado" formControlName="masa_magra" placeholder="MASA MAGRA" class="form-control text-center" aria-label="MASA MAGRA" aria-describedby="inputGroup-sizing-default">
                            <div class="input-group-append">
                              <span class="input-group-text"><b>Kg</b></span>
                            </div>
                        </div>
                        -->

                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text width_clasificacion" id="inputGroup-sizing-default"><b>CLASIFICACIÓN</b></span>
                          </div>
                          <input type="text" id="{{ semaforo }}" formControlName="resultado" placeholder="CLASIFICACIÓN" class="form-control text-center" aria-label="CLASIFICACIÓN" aria-describedby="inputGroup-sizing-default">
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </form>
        </div>

        <span style="font-size: small;">Recomienda este Test</span><br>
        <!-- Sharingbutton WhatsApp --> 
        <a class="resp-sharing-button__link" href="https://api.whatsapp.com/send?text=Hola%20te%20invito%20a%20conocer%20este%20test%20en%20SporTest%20-%20Test%20Porcentaje%20Grasa%20(Durnin%20y%20Womersley)%20https%3A%2F%2Fsportest.app%2Fpublic%2Ffp-durnin-womersley" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z"/></svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Facebook -->
        <a class="resp-sharing-button__link" href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsportest.app%2Fpublic%2Ffp-durnin-womersley" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Telegram -->
        <a class="resp-sharing-button__link" href="https://telegram.me/share/url?text=Hola%20te%20invito%20a%20conocer%20este%20test%20en%20SporTest%20-%20Test%20Porcentaje%20Grasa%20(Durnin%20y%20Womersley)&amp;url=https%3A%2F%2Fsportest.app%2Fpublic%2Ffp-durnin-womersley" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--telegram resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z"/></svg>
            </div>
          </div>
        </a>      
        <!-- Sharingbutton LinkedIn -->
        <a class="resp-sharing-button__link" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fsportest.app%2Fpublic%2Ffp-durnin-womersley&amp;title=SporTest%20-%20Test%20Porcentaje%20Grasa%20(Durnin%20y%20Womersley)&amp;summary=SporTest%20-%20Test%20Porcentaje%20Grasa%20(Durnin%20y%20Womersley)&amp;source=https%3A%2F%2Fsportest.app%2Fpublic%2Ffp-durnin-womersley" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z"/></svg>
            </div>
          </div>
        </a>
        <!-- Sharingbutton Twitter -->
        <a class="resp-sharing-button__link" href="https://twitter.com/intent/tweet/?text=Hola%20te%20invito%20a%20conocer%20este%20test%20en%20SporTest%20-%20Test%20Porcentaje%20Grasa%20(Durnin%20y%20Womersley)&amp;url=https%3A%2F%2Fsportest.app%2Fpublic%2Ffp-durnin-womersley" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg>
            </div>
          </div>
        </a>

      </div>
  
      <!--
      <div class="col-12 col-md-12 col-lg-6 text-center p-0">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a class="nav-item nav-link active titulo_tab pt-2 pb-2 pl-2 pr-2" id="nav-objetivo-tab" data-toggle="tab" href="#nav-objetivo" role="tab" aria-controls="nav-objetivo" aria-selected="true"><div>Objetivo</div></a>
              <a class="nav-item nav-link titulo_tab pt-2 pb-2 pl-2 pr-2" id="nav-procedimiento-tab" data-toggle="tab" href="#nav-procedimiento" role="tab" aria-controls="nav-procedimiento" aria-selected="false"><div>Procedimiento</div></a>
              <a *ngIf="test?.formula" class="nav-item nav-link titulo_tab pt-2 pb-2 pl-2 pr-2" id="nav-formula-tab" data-toggle="tab" href="#nav-formula" role="tab" aria-controls="nav-formula" aria-selected="false"><div>Fórmula</div></a>
              <a class="nav-item nav-link titulo_tab pt-2 pb-2 pl-2 pr-2" id="nav-referencia-tab" data-toggle="tab" href="#nav-referencia" role="tab" aria-controls="nav-referencia" aria-selected="false"><div>Referencias</div></a>
            </div>
          </nav>
          <div class="tab-content texto_tab" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-objetivo" role="tabpanel" aria-labelledby="nav-objetivo-tab">
              <div class="card-body text-left text-align-left pb-4">{{ test.objetivo }}</div>
            </div>
  
            <div class="tab-pane fade" id="nav-procedimiento" role="tabpanel" aria-labelledby="nav-procedimiento-tab">
                <div class="card-body text-left text-align-left pb-4">
                  <div>
                      <li *ngFor="let i of test.procedimiento?.general; let ind = index">
                        {{ i.item }}
                      </li>
                  </div>
                  <br>
  
                  <div *ngIf="test.procedimiento?.videos">
                      <span><b>VIDEOS</b></span><hr>
                      <li *ngFor="let i of test.procedimiento?.videos; let ind = index">
                        <a href="{{ i.link }}" target="_blank">{{ i.nombre }}</a>
                      </li>
                  </div>
                   <br>
  
                  <div *ngIf="test.procedimiento?.mujeres">
                      <span><b>MUJERES</b></span><hr>
                      <li *ngFor="let i of test.procedimiento?.mujeres; let ind = index">
                        {{ i.item }}
                      </li>
                  </div>
                   <br>
  
                  <div *ngIf="test.procedimiento?.hombres">
                      <span><b>HOMBRES</b></span><hr>
                      <li *ngFor="let i of test.procedimiento?.hombres; let ind = index">
                        {{ ind+1 }} - {{ i.item }}
                      </li>
                  </div>
                </div>
            </div>
  
            <div *ngIf="test?.formula" class="tab-pane fade" id="nav-formula" role="tabpanel" aria-labelledby="nav-formula-tab">
                <div class="card-body text-left text-align-left pb-4">
                    <li *ngFor="let i of test?.formula; let ind = index">
                      {{ i.item }}
                    </li>
                </div>
            </div>
            <div class="tab-pane fade" id="nav-referencia" role="tabpanel" aria-labelledby="nav-referencia-tab">
                <div class="card-body text-left text-align-left pb-4">
                    <li *ngFor="let i of test?.referencias; let ind = index">
                      {{ i.item }}
                    </li>
                </div>
            </div>
          </div>
      </div>
      -->

      <div class="col-12 col-md-12 col-lg-6">

        <div class="accordion" id="accordionExample">
  
          <div class="card" *ngIf="test?.objetivo">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" disabled type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Objetivo
                </button>
              </h2>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body text-left">
                {{ test.objetivo }}
              </div>
            </div>
          </div>
  
          <div class="card" *ngIf="test?.procedimiento">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" disabled type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  Procedimiento
                </button>
              </h2>
            </div>
            <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body text-left">
                  <div>
                      <li *ngFor="let i of test.procedimiento?.general; let ind = index">
                        {{ i.item }}
                      </li>
                  </div>
  
                  <div *ngIf="test.procedimiento?.videos">
                      <span><b>VIDEOS</b></span><hr>
                      <li *ngFor="let i of test.procedimiento?.videos; let ind = index">
                        <a href="{{ i.link }}" target="_blank">{{ i.nombre }}</a>
                      </li>
                  </div>
  
                  <div *ngIf="test.procedimiento?.mujeres">
                      <span><b>MUJERES</b></span><hr>
                      <li *ngFor="let i of test.procedimiento?.mujeres; let ind = index">
                        {{ i.item }}
                      </li>
                  </div>
  
                  <div *ngIf="test.procedimiento?.hombres">
                      <span><b>HOMBRES</b></span><hr>
                      <li *ngFor="let i of test.procedimiento?.hombres; let ind = index">
                        {{ ind+1 }} - {{ i.item }}
                      </li>
                  </div>
              </div>
            </div>
          </div>
  
          <div class="card" *ngIf="test?.formula">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" disabled type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                  Fórmula
                </button>
              </h2>
            </div>
            <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div class="card-body text-left">
                <li *ngFor="let i of test?.formula; let ind = index">
                  {{ i.item }}
                </li>
              </div>
            </div>
          </div>
  
          <div class="card" *ngIf="test?.referencias">
            <div class="card-header" id="headingFour">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" disabled type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                  Referencias
                </button>
              </h2>
            </div>
            <div id="collapseFour" class="collapse show" aria-labelledby="headingFour" data-parent="#accordionExample">
              <div class="card-body text-left">
                <li *ngFor="let i of test?.referencias; let ind = index">
                  {{ i.item }}
                </li>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  