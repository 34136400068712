import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseTest } from './response-test';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiTestService {

  //Prod
  baseurl = 'https://serv-dev-dot-sportest-a1588.ue.r.appspot.com/';

  //Testing
  //baseurl = "https://testing-dot-sportest-a1588.ue.r.appspot.com/"

  // baseurl = 'https://serv-sportest-dot-sportest-a1588.ue.r.appspot.com/'; // API
  // baseurl = 'https://us-east1-sportest-a1588.cloudfunctions.net/'; cloud functions

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Categoría Salud
  FcmaxTest(data): Observable<ResponseTest> {
    console.log(data);
    return this.http.post<ResponseTest>(this.baseurl + 'test_fcmax', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  ImcTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_imc', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  MmaeSarcopeniaTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_mmae_sarcopenia', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  ParqAndYouTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_parq', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  WaistPerimeterTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_waist_perimeter', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  GastocaloricoTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_gasto_calorico', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  // Categoría Salud


  // Categoría Antropometria
  FpCarterTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fp_carter', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  FpDurninWomersleyTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fp_durnin_womersley', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  FpFaulknerTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fp_faulkner', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  FpJPSevenPTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fp_jackson_pollock_7p', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  FpJPThreePTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fp_jackson_pollock_3p', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  FpSlaughterTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fp_slaughter', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  FpWeltmanTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fp_weltman', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  FpWithersTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fp_withers', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  // Categoría Antropometria


  // Categoría Aptitud Física
  ArmCurlTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_arm_curl', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  BenchPressRmTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_bench_press_rm', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  BruceTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_bruce', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  ChairSitAndReachTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_chair_sit_and_reach', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  ChairStandTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_chair_stand', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  CooperTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_cooper', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  RuffierTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_ruffier', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  CourseNavetteTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_course_navette', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  GaitSpeedTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_gait_speed', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  HandGripTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_hand_grip', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  LegPowerTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_leg_power', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  LegPressTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_leg_press', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  LongJumpFeetTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_long_jump', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  PushUpTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_push_up', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  RastTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_rast', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  ShuttleRunTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_shuttle_run', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  SitUpTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_sit_up', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  TugTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_tug', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  TwoMinutesStepTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_2minutes_step', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  UmttTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_umtt', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  YoyoIrOneTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_yoyo_ir1', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  YoyoIrTwoTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_yoyo_ir2', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  FmsTest(data): Observable<ResponseTest> {
    return this.http.post<ResponseTest>(this.baseurl + 'test_fms', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }  

  private handleError(error: any): Promise<any> {
    console.error('Ocurrió un error', error);
    return Promise.reject(error.message || error);
  }

  // Dashboard

  TestByUserId(data): Observable<any> {
    return this.http.post<ResponseTest>(this.baseurl + 'list_test', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }  

}
